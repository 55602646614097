
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyABJKkywrB7_RDj7afX-Wot6O5UdFrN8w0",
    authDomain: "cra-pwa-kodai-001.firebaseapp.com",
    projectId: "cra-pwa-kodai-001",
    storageBucket: "cra-pwa-kodai-001.appspot.com",
    messagingSenderId: "486239527826",
    appId: "1:486239527826:web:05b470f97cc553dcb0174b"
  };

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: "BGFWOhX2h9LuWN_fPcfSCVtKtpQbO8qJ6SfAfc-vjUfGCu5-OnPi6uy8iaKB1zBURY5GziC97wAmX62tOFw6nUQ" })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
      } else {
        console.log("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });